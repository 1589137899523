import { navigate } from "gatsby-link"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../components/Layout"
import SignContainer from "../containers/SignContainer"
import PaymentsContainer from "../containers/PaymentsContainer"
import { localStorgeGet, popupFn } from "../utils/util"

//결제 콜백 페이지
const authOrder = props => {
  const { postPaymentsSuccess, postPaymentsFail } = props

  //결제 실패
  const failFn = async req => {
    let paySeq = localStorgeGet("paySeq")
    let temp = { paySeq: paySeq, payResult: req }
    let result = await postPaymentsFail(temp)
    if (result) {
      // alert("결제에 실패하였습니다.")
      navigate("/my/point")
      localStorage.removeItem("paySeq")
    }
  }

  //결제 성공
  const sussFn = async req => {
    let orderIdTemp = ""
    let paymentKey = ""
    let amount = ""
    let reqTemp = req.split("&")
    let paySeq = localStorgeGet("paySeq")
    for (let i of reqTemp) {
      if (i.indexOf("orderId=") != -1) orderIdTemp = i.split("orderId=")[1]
      if (i.indexOf("paymentKey=") != -1) paymentKey = i.split("paymentKey=")[1]
      if (i.indexOf("amount=") != -1) amount = i.split("amount=")[1]
    }
    let temp = { orderId: orderIdTemp, paymentKey: paymentKey, amount: amount, payRequest: req, paySeq: paySeq }

    let result = await postPaymentsSuccess(temp)
    if (result) {
      popupFn("포인트가 충전되었습니다.")
      navigate("/my/point")
      localStorage.removeItem("paySeq")
    } else {
      popupFn("결제에 실패하였습니다.")
      navigate("/my/point")
      localStorage.removeItem("paySeq")
    }
  }

  useEffect(() => {
    if (props.location.search) {
      let orderInfo = props.location.search
      if (orderInfo.indexOf("PAY_PROCESS_CANCELED") != -1) {
        failFn(orderInfo)
      } else {
        sussFn(orderInfo)
      }
    }
  }, [])
  return (
    <Layout>
      <div></div>
    </Layout>
  )
}

export default PaymentsContainer(SignContainer(authOrder))
